import React from "react";
import martini from "../images/martini.svg";
import wavegif from "../images/wavegif.gif";

const DigitalWonder = () => {
  return (
    <div className="bg-[#080D13] min-h-screen flex items-center justify-center p-4">
      <div className=" w-full">
        <div className="relative rounded-3xl overflow-hidden">
          <div className="relative flex flex-col md:flex-row items-center p-6 md:p-12 md:py-24 rounded-3xl">
            <div className="w-full md:w-1/2 mb-6 md:mb-0">
              <img
                src={martini}
                alt="Maritime Port Illustration"
                className="w-full h-auto rounded-2xl"
              />
            </div>

            <div className="w-full md:w-1/2 md:px-8 lg:ml-10 text-center md:text-start flex flex-col items-center md:items-start md:space-y-12 xl:space-y-20">
              <img src={wavegif} className="w-16" alt="" />
              <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-heading font-bold text-white mb-10">
                From Maritime
                <br /> Marvel to{" "}
                <span className="bg-gradient-to-r from-[#11D4D9] to-[#CD0E59] bg-clip-text text-transparent font-normal">
                  Digital Wonderland
                </span>
              </h1>
              <p className="text-white text-sm md:text-base lg:text-p1 text-center md:text-start">
                Discover the rich legacy of Atlantis Khalifa Port and witness
                its evolution from a cornerstone of global trade to a cutting-
                edge digital twin. Explore the milestones and innovations that
                have shaped its journey, and join us as we continue to push the
                boundaries of what is possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalWonder;
