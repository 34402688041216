import React ,{useState} from "react";
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="py-4 md:py-6  w-screen px-4 md:px-12 lg:pl-28 flex justify-between items-center bg-transparent fixed z-20 ">
        <h1 className="text-4xl md:w-[259px] md:h-[71px] md:text-Atlantis font-bold text-white tracking-10-percent">
          ATLANTIS
        </h1>
        <div className="flex lg:hidden">
        <button onClick={toggleMenu} className="text-white text-2xl md:mb-10">
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
        <div className={`flex-col lg:flex lg:flex-row lg:items-center gap-4 lg:mb-auto xl:gap-10 text-white text-sm xl:text-xl absolute lg:static top-16 left-0 w-full lg:w-auto bg-slate-800 lg:bg-transparent transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}>
          <Link to=""
            className="block lg:inline-block px-4 py-2 hover:cursor-pointer hover:scale-105"
          >
            Home
          </Link>
          <Link to=""
            className="block md:inline-block px-4 py-2 hover:cursor-pointer hover:scale-105"
          >
            Why Choose Us
          </Link>
          <Link to=""
            className="block lg:inline-block px-4 py-2 hover:cursor-pointer hover:scale-105"
          >
            Explore
          </Link>
          <Link to=""
            className="block lg:inline-block px-4 py-2 hover:cursor-pointer hover:scale-105"
          > 
            Contact Us
          </Link>
          <Link to=""
            className="block px-4 py-2 md:px-8 md:py-1 text-[10px] hover:cursor-pointer hover:scale-105 bg-[#CD0E59] rounded-full "
          >
            Try It
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;

// ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
