import React, { useState, useEffect } from "react";
import CarouselDots from "./components/CarouselDots";
import sail from "../images/Sail.gif";
import welcomebg1 from "../images/welcomebg1.svg";
import Maskgroup2 from "../images/Maskgroup.svg";
import Maskgroup3 from "../images/Maskgroup.svg"; // Importing images directly
import wavegif from "../images/wavegif.gif";
import shipbg1 from "../images/shipbg1.jpg";
import shipbg2 from "../images/shipbg2.jpg";
import shipbg4 from "../images/shipbg4.jpg";
import mbbg1 from "../images/mbbg1.jpg"; 

const backgroundImages = [welcomebg1, shipbg1, shipbg2, shipbg4];

const WelcomePageCarousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []); 

  return (
    <>
      <div
        className="hidden md:block transition-all duration-500 ease-in-out mt-0 max-w-full h-full py-28 md:py-32 xl:py-40 object-fill bg-cover"
        style={{
          backgroundImage: `url(${backgroundImages[currentImageIndex]})`,
        }}
      >
        <div className="px-4 sm:px-8 md:px-16 lg:px-28 space-y-5 md:space-y-7 flex flex-col items-start"> 
          <img src={wavegif} className="w-[84px]" alt="" />
          {/* Title Section */}
          <h1 className="max-w-screen-xl text-3xl md:text-5xl lg:text-7xl xl:text-[85px] font-extrabold text-white md:leading-[127.5px]">
            WELCOME TO{" "}
            <span className="bg-gradient-to-r from-[#11D4D9] to-[#CD0E59] bg-clip-text text-transparent leading-normal">
              ATLANTIS <br />
              KHALIFA{" "}
            </span>
            PORT
          </h1>

          {/* Subheading */}
          <p className="bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent text-left sm:text-left sm:text-2xl md:text-3xl lg:text-sub-heading">
            The Future of Virtual Exploration
          </p>
   
          {/* Description Paragraph */}
          <p className="text-white text-start sm:text-left sm:text-sm md:text-lg lg:text-xl xl:text-2xl leading-loose md:w-4/6"
          style={{fontFamily: "Inter-Regular"}}>
            Experience the magic of Atlantis Khalifa Port from anywhere in the
            world. Dive into an immersive digital twin of one of the most
            advanced ports and explore its wonders like never before.
          </p>

          {/* Button Section */}
          <div className="flex justify-start ">
            <button className="flex items-center justify-between px-4 py-2 md:py-0 md:px-3 space-x-5 bg-[#11D4D9] text-white font-semibold rounded-md border-2">
              <span className="mr-2 text-black font-normal">EXPLORE MORE</span>
              <img
                src={sail}
                alt="icon"
                className="w-10 sm:w-8 sm:h-8 md:w-10 md:h-10"
              />
            </button>
          </div>
        </div>

        {/* Carousel Dots */}
        <CarouselDots
            currentImageIndex={currentImageIndex}
            backgroundImages={backgroundImages}
            onDotClick={setCurrentImageIndex}
          />
      </div>


      {/** sm device*/}
      <div className="md:hidden bg-Maskgroup mt-0 max-w-full h-full py-28 md:py-32 xl:py-40 object-fill">
      <div className="px-4 sm:px-8 md:px-16 lg:px-28 space-y-5 md:space-y-7 flex flex-col items-start">
          <img src={wavegif} className="w-16" alt="" />
          {/* Title Section */}
          <h1 className="max-w-screen-xl text-3xl md:text-5xl lg:text-7xl font-extrabold text-white md:leading-normal">
            WELCOME TO{" "}
            <span className="bg-gradient-to-r from-[#11D4D9] to-[#CD0E59] bg-clip-text text-transparent leading-normal">
              ATLANTIS <br />
              KHALIFA{" "}
            </span>
            PORT
          </h1>

          {/* Subheading */}
          <p className="bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent text-left sm:text-left sm:text-2xl md:text-3xl lg:text-4xl">
            The Future of Virtual Exploration
          </p>

          {/* Description Paragraph */}
          <p className="text-white text-start sm:text-left sm:text-sm md:text-lg lg:text-xl leading-loose md:w-3/6">
            Experience the magic of Atlantis Khalifa Port from anywhere in the
            world. Dive into an immersive digital twin of one of the most
            advanced ports and explore its wonders like never before.
          </p>

          {/* Button Section */}
          <div className="flex justify-start ">
            <button className="flex items-center justify-between px-4 py-2 md:py-0 md:px-3 space-x-5 bg-[#11D4D9] text-white font-semibold rounded-md border">
              <span className="mr-2 text-black font-normal">EXPLORE MORE</span> 
              <img
                src={sail}
                alt="icon"
                className="w-10 sm:w-8 sm:h-8 md:w-10 md:h-10"
              />
            </button>
          </div>
        </div>
        </div>
    </>
  );
};

export default WelcomePageCarousel;
