import React from "react";
import "./LandingStyle.css";
import exp1 from "../images/exp1.svg";
import exp2 from "../images/exp2.svg";
import exp3 from "../images/exp3.svg";
import exp4 from "../images/exp4.svg";
import exp5 from "../images/exp5.svg";
import exp6 from "../images/exp6.svg";
import exp7 from "../images/exp7.svg";
import exp8 from "../images/exp8.svg";
import exp9 from "../images/exp9.svg";
import wavegif from "../images/wavegif.gif";

const ExploreMeta = () => {
  return (
    <>
      <div className="bg-[#000E10] pt-10 md:pt-28 md:py-20 space-y-16">
        <div className="flex flex-col items-center space-y-5">
          <img src={wavegif} className="w-[82px]" alt="" />
          <h1 className="text-white text-3xl text-center md:text-4xl lg:text-heading font-extrabold">
            Explore Metaverse of Atlantis <br />
            <span className=" font-normal bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent py-2">
              Digital Twin?
            </span>
          </h1>
        </div>

        <div className="wrapper grid grid-cols-1 mx-auto max-w-screen-2xl">
          <img src={exp1} className="honeycomb1 w-full object-contain" />
          <img src={exp3} className="honeycomb1 w-full object-contain" />
          <img src={exp8} className="honeycomb1 w-full object-contain" />
          <img src={exp6} className="honeycomb w-full object-contain" />
          <img src={exp2} className="honeycomb1 w-full object-contain" />
          <img src={exp4} className="honeycomb1 w-full object-contain" />
          <img src={exp9} className="honeycomb w-full object-contain" />
          <img src={exp5} className="honeycomb w-full object-contain" />
          <img src={exp7} className="honeycomb w-full object-contain" />
        </div>
      </div>
    </>
  );
};

export default ExploreMeta;
