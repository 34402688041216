import React from "react";
import fb from "../images/fb.svg";
import insta from "../images/insta.svg";
import lin from "../images/lin.svg";
import tr from "../images/tr.svg";
import Factionlogo from "../images/Factionlogo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#070C11]">
      <div className="mx-auto w-screen xl:w-full px-2 max-w-screen-2xl p-4 py-6 md:px-20 text-[#FFFFFF] space-y-5">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 md:w-3/6 lg:w-2/6 space-y-5">
            <div className="flex flex-col items-end w-max">
              <div className="text-4xl md:text-Atlantis tracking-10-percent  font-extrabold">ATLANTIS</div>
              <div className="">Powered by</div> 
              <div>
                <img src={Factionlogo} alt="Footer Logo" className="w-28" />
              </div>
            </div>

            <p className="text-xs leading-loose text-[#FFFFFF]">
              Embark on your digital transformation journey with Atlantis Digital Twin. Contact us to learn more about how our platform can revolutionize your operations and unlock new possibilities.
            </p>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-3 md:px-10 lg:px-0">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-[#E6E6E8] uppercase">
                Solutions
              </h2>
              <ul className="text-[#FFFFFF] font-medium text-sm">
                <li className="mb-4">
                  <Link to="" className="hover">
                    Solution
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="" className="hover">
                    Faction
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="" className="hover">
                    Promatheus
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h2 className="mb-6 text-sm font-semibold text-[#E6E6E8] uppercase">
                Company
              </h2>
              <ul className="text-[#FFFFFF] font-medium text-sm">
                <li className="mb-4">
                  <Link to="" className="hover">
                    About Us
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="" className="hover">
                    Team
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="" className="hover">
                    Advisors
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h2 className="mb-6 text-sm font-semibold text-[#E6E6E8] uppercase">
                Contact Us
              </h2>
              <p className="text-sm text-[#FFFFFF]">
                info@atlantisdigitaltwin.com
                <br />
                Phone: +123-456-7890
                <br />
                Address: 123 Future Street,
                <br /> Innovation City, Country
              </p>
            </div>
          </div>
        </div>

        <hr className="border-gray-700 sm:mx-auto" />

        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-[#FFFFFF] sm:text-center">
            © [2024] Atlantisdigitaltwin.com All Rights Reserved.
          </span>

          <div className="flex sm:justify-center mt-5 md:mt-0">
            <Link to="" className="text-gray-400 hover:text-white">
              <img src={fb} alt="Facebook" />
              <span className="sr-only">Facebook page</span>
            </Link>
            <Link to="" className="text-gray-400 hover:text-white ms-5">
              <img src={tr} alt="Twitter" />
              <span className="sr-only">Twitter page</span>
            </Link>
            <Link to="" className="text-gray-400 hover:text-white ms-5">
              <img src={insta} alt="Instagram" />
              <span className="sr-only">Instagram page</span>
            </Link>
            <Link to="" className="text-gray-400 hover:text-white ms-5">
              <img src={lin} alt="LinkedIn" />
              <span className="sr-only">LinkedIn page</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
