import { useState } from "react";
import sail from "../images/Sail.gif";
import wavegif from "../images/wavegif.gif";

const JoinUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log("Form submitted:", { firstName, lastName, email });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-[#080D13] space-y-16 pb-10 md:pb-20">
        <img src={wavegif} className="w-[84px]" alt="" />
        <h1 className="text-white px-2 text-3xl lg:text-heading font-extrabold text-center">
          Join the Future with Atlantis
          <br />
          <span className="font-normal bg-gradient-to-r from-[#CD0E59] to-[#11D4D9] bg-clip-text text-transparent py-2">
            Digital Twin?
          </span>
        </h1>

        <div className="w-auto h-auto xl:w-[1318px] xl:h-[482px] max-h-full py-10 md:py-28 px-4 lg:px-28 rounded-[58px] bg-[#070C12] border border-[#11D4D9] flex justify-center items-center">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-evenly space-y-7 lg:space-y-16 w-full max-w-[800px]" // Added max width
          >
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
              <div className="flex-1">
                <label htmlFor="firstName" className="sr-only">
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  required
                  className="w-full bg-transparent border-b border-teal-400 text-[#FFFFFF] placeholder-[#FFFFFF] focus:outline-none focus:border-teal-500 md:text-xl py-2 leading-relaxed"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="lastName" className="sr-only">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  required
                  className="w-full bg-transparent border-b border-teal-400 text-[#FFFFFF] placeholder-[#FFFFFF] focus:outline-none focus:border-teal-500 md:text-xl py-2 leading-relaxed"
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="sr-only">
                Email Address
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                required
                className="w-full bg-transparent border-b border-teal-400 text-[#FFFFFF] placeholder-[#FFFFFF] focus:outline-none focus:border-teal-500 md:text-xl py-2 leading-relaxed"
              />
            </div>

            <button className="flex items-center justify-between px-4 bg-[#11D4D9] text-white  rounded-md w-max space-x-16 mx-auto border-2 border-white lg:w-[247px] lg-h-[44px]">
              <span className="mr-2 text-black font-normal">JOIN NOW</span>
              <img src={sail} alt="icon" className="w-12 md:w-12" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
